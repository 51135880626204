<template>
    <div class="error-page">
        <div v-if="this.data.order_id != undefined">
            <div class="order">
                <span>{{$t('Order Number')}}:</span>
                <span>{{data.order_id}}</span>
                <img class="copy-w" src="../../assets/pictures/reusdt/copy@1x.png" data-clipboard-action="copy"
                     :data-clipboard-text="data.order_id" @click="copy('.copy-w')"/>
            </div>
            <div class="bs-panel tips">{{$t('Sorry, the recharge channel is under maintenance, please change to another channel.')}}</div>
<!--            <div class="error-info" v-if="data.msg != ''"><span>{{data.msg}}</span></div>-->
        </div>
    </div>
</template>

<script>
    import helper from "../../utils/helper";
    import Clipboard from "clipboard";

    export default {
        components: {},
        data() {
            return {
                name: '',
                html: '',
                data: {},
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            getData() {
                this.$axios
                    .get('/help/get_row?id=1')
                    .then((res) => {
                        this.html = res.data;
                    });
            },
            copy(val) {
                let clipboard = new Clipboard(val);
                let that = this;
                clipboard.on("success", (e) => {
                    that.$toast.success(this.$t('复制成功'));
                    // 释放内存
                    clipboard.destroy();
                });
                clipboard.on("error", (e) => {
                    // 不支持复制
                    this.$toast.fail(this.$t('复制失败'));
                    // 释放内存
                    clipboard.destroy();
                });
            },
        },
		mounted() {
            if (this.$route.query.sign !== undefined) {
                let sign = this.$route.query.sign;
                console.log(helper.decrypt(sign));
                let json_str = atob(helper.decrypt(sign));
                this.data = JSON.parse(json_str);
                console.log(this.data);
            }
		}

    }
</script>
<style scoped>
    .error-page{
        padding: 15px;
    }
    .error-page .tips{
        border-radius: 10px;
        padding: 10px;
        font-size: 14px;
    }

    .error-page .order{
        margin: 10px auto;
        border-radius: 10px;
        padding: 8px 10px;
        font-size: 14px;
        background-color: #ffe87e;
        color: #101010;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .error-page .order img{
        width: 30px;
    }

    .error-page .error-info{
        margin-top: 10px;
        border-radius: 10px;
        padding: 10px;
        font-size: 12px;
        color: #cccccc;
        background-color: #f5f2f0;
    }
</style>